<template>
    <Head v-if="product">
        <title>{{ siteTitle }} | {{ parentProduct.seo.title }}</title>
        <meta name="description" :content="pageDescription">
    </Head>
    <DefaultLayout>
        <TextMasthead background-colour="white" />
        <main>
            <section ref="product-info" class="bg-white">
                <Container class="pb-16 pt-16 lg:pb-32">
                    <a @click="$router.go(-1)" title="Previous Page" class="mb-8 block" data-aos="fade-down"><ArrowSmallLeftIcon class="inline h-4 w-4" /> Back</a>
                    <template v-if="!product">
                        <div class="relative h-24 mt-24">
                            <Loading/>
                        </div>
                    </template>
                    <div v-else class="grid grid-cols-12 gap-4 lg:gap-8">
                        <div class="col-span-12 mb-8 lg:col-span-6 lg:mb-0" data-aos="fade-right">
                            <div class="grid grid-flow-col auto-cols-full scroll-bar py-2 gap-4 items-center mb-8 lg:mb-0">
                                <template v-if="product.images.length > 0">
                                    <img v-for="(image, index) in product.images" :key="index" :src="image.url" :alt="'Image Thumbnail: ' + image.name" loading="lazy" />
                                </template>
                                <img v-else-if="product.thumbnail" :src="product.thumbnail.url" :alt="'Image: ' + product.thumbnail.name" loading="lazy" />
                                <template v-else-if="parentProduct.images.length > 0">
                                    <img v-for="(image, index) in parentProduct.images" :key="index" :src="image.url" :alt="'Image Thumbnail: ' + image.name" loading="lazy" />
                                </template>
                                <img v-else :src="parentProduct.thumbnail.url" :alt="'Image: ' + parentProduct.thumbnail.name" loading="lazy" />
                            </div>
                        </div>
                        <div class="col-span-12 lg:col-span-5 lg:col-start-8" data-aos="fade-in">
                            <img v-if="brand && brand.thumbnail" :src="brand.thumbnail.url" :title="brand.name" class="max-h-[50px] mb-6" />
                            <h1 class="text-5xl lg:text-5xl mb-4">{{ parentProduct.title }}
                                <span v-if="parentProduct.id != product.id" class="block mt-4 text-4xl lg:text-4xl font-normal">
                                    {{ productCategory(product, 'colour').name }}
                                </span>
                            </h1>
                            <p class="text-lg mb-4" v-if="parentProduct.code">{{ parentProduct.code }}</p>
                            <p class="mb-4" v-if="parentProduct.description">{{ parentProduct.description }}</p>
                            <template v-if="product">
                                <div class="mb-8">
                                    <div class="grid grid-flow-col">
                                        <div class="border border-white text-center">
                                            <span class="block bg-green text-white font-semibold p-1">1+</span>
                                            <span class="block border border-green p-1">{{ moneyFormat(taxFormat(product.discount_price, includeTax)) }}</span>
                                        </div>
                                        <div v-for="(discount, index) in product.discounts.sort((a,b) => a.quantity - b.quantity)" :key="index" class="border border-white text-center">
                                            <span class="block bg-green text-white font-semibold p-1">{{ 'quantity' in discount ? discount.quantity : 1 }}+</span>
                                            <span class="block border border-green p-1">{{ moneyFormat(taxFormat(discount.value, includeTax)) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div>
                                <template v-if="parentProduct && colourVariants">
                                    <label class="block">Choose Colour</label>
                                    <div class="grid py-2 gap-1 items-center grid-cols-6 lg:grid-cols-9 mb-4">
                                        <a v-for="(childProduct, index) in colourVariants(parentProduct)" :key="index"
                                           @click.prevent="selectColourVariant(childProduct)"
                                           class="border p-[1px] border-black"
                                           :class="{'border-2 ': product.id == childProduct.id}" :title="childProduct.name">
                                            <img v-if="getColourImage(childProduct)" :src="getColourImage(childProduct)" :alt="'Image Thumbnail: ' + childProduct.title" loading="lazy" />
                                            <span v-else>{{ productCategory(childProduct, 'colour').name }}</span>
                                        </a>
                                    </div>
                                </template>

                                <template v-if="colourOptionId && sizeVariants">
                                    <label class="block mb-1">Choose Sizes</label>
                                    <div class="grid grid-flow-col">
                                        <div v-for="cp in sizeVariants.sort((a,b) => a.id - b.id)" :key="cp.id">
                                            <div class="border border-white text-center">
                                                <span class="block bg-black text-white font-semibold p-1">{{ cp.title }} </span>
                                                <input type="number"
                                                       class="text-center w-full block border border-black p-1"
                                                       :class="{'opacity-50': !productHasInventory(cp)}"
                                                       :disabled="!productHasInventory(cp)"
                                                       :value="[cp.id in selectedProducts ? selectedProducts[cp.id].quantity : 0]"
                                                       @change="addProductToBasket(cp, $event.target.value)"
                                                       :max="[cp.track_inventory ? cp.inventory : 100]"
                                                       min="1" value="0"
                                                       required
                                                >
                                            </div>
                                            <p v-if="cp.track_inventory" class="text-center mt-1 mb-1 text-xs"
                                               :class="[cp.inventory === 0 ? 'text-orange' : 'text-green']">
                                                {{ cp.inventory === 0 ? 'Unavailable' : (cp.inventory > 500 ? '500+' : cp.inventory) + ' available' }}
                                            </p>
                                            <p v-if="cp.discount_price > product.discount_price" class="text-center mt-0 mb-0 text-xs">
                                                +{{ moneyFormat(taxFormat(cp.discount_price - product.min_price, includeTax))}}
                                                <span class="block">({{ includeTax ? 'Inc' : 'Ex'}}. VAT)</span>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="mt-4 mb-8 text-center">
                                <p class="flex items-end justify-between text-2xl mb-8">
                                    <span class="font-normal">{{ calculateItems }} item(s)</span>
                                    <span class="font-semibold lg:text-3xl">{{ moneyFormat(taxFormat(calculateTotal, includeTax)) }}
                                        <span class="font-normal text-xs">{{ includeTax ? 'Inc' : 'Ex'}}. VAT</span>
                                    </span>
                                </p>
                               <Button :disabled="!Object.values(this.selectedProducts).length > 0" @click.prevent="addProductsToBasket()" background-colour="black" text-colour="white" :class="'w-full block'">
                                    Add to basket
                                </Button>
                                <Alert v-if="errors.length > 0" :messages="errors" class="mt-3" />
                            </div>

                            <AccordionList :items="productContent"/>

                            <template v-if="product.attributes.length > 0">
                                <Accordion title="Specification" class="py-6" :open="true">
                                    <table class="w-full">
                                        <template v-for="(attribute, index) in product.attributes" :key="index">
                                            <tr v-if="['Colour Image'].includes(attribute.name) === false">
                                                <th class="border border-white bg-black text-white text-center">
                                                    <span class="block font-semibold p-1">{{ attribute.name }}</span>
                                                </th>
                                                <td class="border border-white text-center">
                                                    <span class="block border border-black p-1">
                                                        <span v-for="(value, ai) in attribute.values" :key="ai" class="mr-1">
                                                            {{ value.value }}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </Accordion>
                            </template>

                            <template v-if="Object.keys(attributeGroups).length > 0">
                                <Accordion title="Size Conversions" class="py-6">
                                    <table class="w-full">
                                        <template v-for="(values, name) in attributeGroups" :key="name">
                                            <tr>
                                                <th class="border border-white bg-black text-white text-center">
                                                    <span class="block font-semibold p-1">{{name }}</span>
                                                </th>
                                                <template v-for="(value, ai) in values" :key="ai">
                                                    <td class="border border-white text-center">
                                                        <span class="block border border-black p-1">{{ value.value }}</span>
                                                    </td>
                                                </template>
                                            </tr>
                                        </template>
                                    </table>
                                </Accordion>
                            </template>

                            <template v-if="parentProduct.files.length > 0">
                                <Accordion title="Specification and Downloads" class="py-6">
                                    <ul>
                                        <li v-for="asset in parentProduct.files" :key="asset.id">
                                            <a :href="asset.url + '?response-content-disposition=attachment;'" :alt="'Download: ' + asset.name">{{ asset.name }}</a>
                                        </li>
                                    </ul>
                                </Accordion>
                            </template>
                        </div>
                    </div>
                </Container>
            </section>
            <ContentSections v-if="page" :pageContent="page" :class="'border-t'"/>
            <RelatedProductSection title="You may also like" :products="relatedProducts.length > 0 ? relatedProducts : recommendedProducts" background-colour="secondary"/>
            <RecentlyViewedProductSection title="Recently viewed" background-colour="primary" />
        </main>
    </DefaultLayout>
</template>

<script>
    import Container from '@/components/Container.vue'
    import TextMasthead from '@/components/TextMasthead.vue'
    import AccordionList from '@/components/elements/AccordionList.vue'
    import Accordion from '@/components/elements/Accordion.vue'
    import LinkButton from '@/components/elements/LinkButton.vue'
    import Button from '@/components/elements/Button.vue'
    import ContentSections from '@/components/ContentSections.vue'
    import Alert from '@/components/elements/Alert.vue'
    import Loading from '@/components/Loading.vue'
    import RelatedProductSection from '@/components/sections/RelatedProductSection.vue'
    import RecentlyViewedProductSection from '@/components/sections/RecentlyViewedProductSection.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import PageMeta from '@/mixins/pageMeta'
    import PageContent from '@/mixins/pageContent'
    import MoneyFormat from '@/mixins/moneyFormat'
    import ProductContent from '@/mixins/productContent'
    import { ArrowSmallLeftIcon } from '@heroicons/vue/24/outline'
    import { Head } from '@vueuse/head'

    export default {
        name: 'ProductListPage',
        inject: ['sdk', 'dataStore', 'errorHandler'],
        mixins: [PageMeta, PageContent, MoneyFormat, ProductContent],
        components: {
            Accordion,
            Container,
            TextMasthead,
            AccordionList,
            ContentSections,
            RelatedProductSection,
            RecentlyViewedProductSection,
            LinkButton,
            Button,
            DefaultLayout,
            ArrowSmallLeftIcon,
            Head,
            Loading,
            Alert
        },
        data() {
            return {
                quantity: 1,
                parentProduct: null,
                product: null,
                selectedProductId: null,
                selectedProducts: {},
                relatedProducts: [],
                recommendedProducts: [],
                errors: [],
                colourOptionId: null
            };
        },
        computed: {
            calculateTotal: function () {
                let total = 0;

                if(Object.values(this.selectedProducts).length > 0){
                    Object.values(this.selectedProducts).forEach(values => {
                        const quantity = parseInt(values.quantity)
                        let productTotal = 0;

                        const selectedProduct = this.parentProduct.child_products.filter((cp) => {
                            if (cp.id === values.product_id) {
                                return cp
                            }
                        })

                        if (selectedProduct.length > 0 && selectedProduct[0].discounts && selectedProduct[0].discounts.length > 0) {
                            selectedProduct[0].discounts.sort((a, b) => a.quantity + b.quantity).forEach(discount => {

                                if (quantity >= discount.quantity) {
                                    productTotal = quantity * discount.value
                                }
                            })
                        }

                        if (productTotal === 0) {
                            productTotal = quantity * selectedProduct[0].discount_price
                        }

                        total += productTotal
                    })
                }

                return total
            },
            calculateItems: function() {
                let items = 0

                if(Object.values(this.selectedProducts).length > 0){
                    Object.values(this.selectedProducts).forEach(values => {
                        items += parseInt(values.quantity)
                    })
                }

                return items
            },
            pageSlug: function () {
                return 'product';
            },
            productSlug: function () {
                return this.$route.params.slug;
            },
            pageDescription: function () {
                if(this.parentProduct.seo.description){
                    return this.parentProduct.seo.description
                }

                return this.defaultPageDescription
            },
            productContent: function () {
                let productContent = [];

                if (this.parentProduct.content.length > 0) {
                    productContent = this.parentProduct.content.map((content, index) => {
                            return {
                                title: content.name,
                                content: content.value,
                                open: index === 0
                            }
                        }
                    );
                }
                return productContent;
            },
            attributeGroups: function () {
                let attributeGroups = {}

                // if (this.parentProduct.child_products.length > 0) {
                //     this.parentProduct.child_products.forEach(childProduct => {
                //         childProduct.attributes.forEach((attribute) => {
                //             attribute.values.forEach((value) => {
                //                 if (!(value.name in attributeGroups)) {
                //                     attributeGroups[value.name] = []
                //                 }
                //
                //                 attributeGroups[value.name].push(value)
                //             })
                //         })
                //     })
                // }

                return attributeGroups
            },
            includeTax: function () {
                return this.dataStore.getIncludeTax() && this.parentProduct.taxable
            },
            sizeVariants: function() {
                const sizeVariants = []
                this.parentProduct.child_products.forEach(childProduct => {
                    childProduct.category_options.forEach(categoryOption => {
                        if (categoryOption.id === this.colourOptionId) {
                            sizeVariants.push(childProduct)
                            return
                        }
                    })
                })


                return sizeVariants
            },
            brand: function() {
                const categoryOption = this.parentProduct.category_options.filter(categoryOption => {
                    if (categoryOption.category.slug === 'brand') {
                        return categoryOption
                    }
                })
                return categoryOption.length > 0 ? categoryOption[0] : null
            }
        },
        methods: {
            resetErrors : function() {
                this.errors = []
            },
            selectColourVariant: function(product){
                this.product = product;

                this.product.category_options.forEach(categoryOption => {
                    if (categoryOption.category.slug === 'colour') {
                        this.colourOptionId = categoryOption.id
                        return
                    }
                })
            },
            loadParentProduct: function (slug) {
                this.sdk.getProductBySlug(slug)
                    .then(response => {
                        if (response.products.length === 0) {
                            this.$router.push({name: '404'})
                            return
                        }

                        this.selectedProducts = {}
                        this.parentProduct = response.products[0];
                        let product = response.products[0];

                        // If we don't have variants then set the selected product to loaded product
                        if (this.parentProduct.child_products.length > 0) {
                            product = this.colourVariants(this.parentProduct)[0]
                        }

                        this.selectColourVariant(product)

                        if(this.parentProduct.related_products){
                            this.relatedProducts = this.parentProduct.related_products
                        }

                        this.loadRecommendedProducts(this.parentProduct)
                        this.dataStore.setRecentlyViewed(this.parentProduct)
                        this.sdk.trackAnalyticByProductId(this.parentProduct.id)
                    }).catch(error => {
                    this.errorHandler.handleError(error);
                })
            },
            loadRecommendedProducts: function (product) {
                const categoryOption = product.category_options.filter((option) => {
                    if ('category' in option && option.category.slug === 'type') {
                        return option
                    }
                })

                if (categoryOption.length > 0) {
                    const filters = [
                        {
                            property: 'parent_product_id',
                            value: 'null',
                            operator: '='
                        },
                        {
                            property: 'category_options',
                            value: categoryOption[0].id,
                            operator: '='
                        },
                        {
                            property: 'inventory',
                            value: 1,
                            operator: '>='
                        }
                    ]
                    this.sdk.getProducts(
                        {page: 1}, filters, [], ['childProducts'])
                        .then(response => {
                            if (response.products.length === 0) {
                                this.recommendedProducts = []
                                return;
                            }

                            this.recommendedProducts = response.products;

                        }).catch(error => {
                        this.errorHandler.handleError(error);
                    })
                }
            },
            addProductToBasket: function (product, quantity) {
                this.resetErrors()

                if (product.track_inventory && product.inventory < quantity) {
                    this.errors = ['Not enough inventory for size "' + product.title + '" to estimate pricing']
                    return false
                }

                this.selectedProducts[parseInt(product.id)] = {
                    product_id: parseInt(product.id),
                    quantity: quantity,
                }
            },
            addProductsToBasket: function () {
                this.resetErrors()
                const selectedProductIds = Object.keys(this.selectedProducts)

                if (!this.dataStore.getBasket()) {
                    this.sdk.createBasket(Object.values(this.selectedProducts)).then(response => {
                        const basket = response.basket;
                        this.dataStore.setBasket(basket);
                        this.emitter.emit('basket-updated', selectedProductIds);
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                        if('errors' in error.response.data){
                            this.errors = [
                                error.response.data.errors[0].message
                            ];
                        }
                        else {
                            this.errors = [
                                'Error processing your request'
                            ];
                        }
                    })

                    return
                }

                let selectedProducts = {...this.selectedProducts}

                // Merge existing basket with new values
                if (this.dataStore.getBasket().products.length > 0) {
                    this.dataStore.getBasket().products.forEach((basketProduct) => {
                        if (!(basketProduct.product.id.toString() in selectedProducts)) {
                            selectedProducts[basketProduct.product.id] = {
                                product_id: parseInt(basketProduct.product.id),
                                quantity: basketProduct.quantity,
                            }
                        }
                    })
                }

                this.sdk.updateBasket(this.dataStore.getBasketReference(), Object.values(selectedProducts))
                    .then(response => {
                        const basket = response.basket;
                        this.dataStore.setBasket(basket);
                        this.emitter.emit('basket-updated', selectedProductIds);
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                        if('errors' in error.response.data){
                            this.errors = [
                                error.response.data.errors[0].message
                            ];
                        }
                        else {
                            this.errors = [
                                'Error processing your request'
                            ];
                        }
                }).finally(() => {
                    this.selectedProducts = {}
                })
            }
        },
        created() {
            this.loadParentProduct(this.productSlug);
            this.loadPageContent();
        }
    }
</script>